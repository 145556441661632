<template>
  <div
    v-if="general"
    ref="siteHeaderElement"
    class="site-header"
    :data-theme="theme"
    :class="{
      '-scrolled': y > scrollHeight && !isNavOpen,
      '-nav-visible': isNavVisible,
      '-nav-open': isNavOpen,
      '-nav-transition': !isNavOpen && isNavVisible
    }"
  >
    <div class="header" v-if="headerContent">
      <div class="column -logo">
        <NuxtLink aria-label="Home" to="/" class="logo">
          <DatoAsset v-if="headerContent.logo" class="asset" :asset="headerContent.logo" />
        </NuxtLink>
        <div id="site-header-slot"></div>
      </div>
      <div class="column">
        <button
          aria-label="Menu"
          aria-controls="navigation"
          class="button"
          type="button"
          @click="toggleNav(1000)"
        >
          <span class="buttontext nav-link-default">
            <span class="text -close" v-show="isNavOpen" v-if="general">{{
              general.closeNavigation
            }}</span>
            <span class="text -open" v-show="!isNavOpen" v-if="general">{{
              general.openNavigation
            }}</span>
          </span>
          <span :class="{ 'is-active': isNavOpen }" class="hamburger hamburger--squeeze">
            <span class="hamburger-box">
              <span class="hamburger-inner"></span>
            </span>
          </span>
        </button>
      </div>
    </div>
    <MainNavigation :is-open="isNavOpen" :is-visible="isNavVisible" />
  </div>
</template>

<script setup lang="ts">
import MainNavigation from '~/components/site-header/MainNavigation.vue';
import useSleep from '~/utils/useSleep';
import { useWindowSize } from '#imports';
import type { GetHeaderQuery, GetGeneralQuery } from '#gql';
import DatoAsset from '~/dato/DatoAsset.vue';
import type { GeneralFragment } from '#gql';

const route = useRoute();

const props = defineProps<{
  theme: 'light' | 'dark' | 'dynamic';
}>();

const lastScrollDirection = ref<'down' | 'up'>('down');
const windowElement = ref<null | Window>(null);
const isNavOpen = ref<boolean>(false);
const isNavVisible = ref<boolean>(false);
const hasScrolledUp = ref<boolean>(false);
const siteHeaderElement = ref<HTMLElement | null>(null);
const isProjectPage = ref<boolean>(false);

const { x, y, directions: scrollDirections } = useScroll(windowElement);
const { height: windowHeight } = useWindowSize();

const { data: dataHeader } = await useAsyncGql({
  operation: 'GetHeader'
});

const headerContent = (dataHeader.value as GetHeaderQuery).header;

const general = inject<GeneralFragment>('general');

const showBackLink = computed(function () {
  return typeof route.params.projekt_id === 'string';
});

const scrollHeight = computed(function () {
  if (isProjectPage.value) {
    return windowHeight.value - 70;
  }
  return 50;
});

const theme = computed(function () {
  if (props.theme === 'dynamic') {
    if (showBackLink.value) {
      return 'light';
    }
    return 'dark';
  }
  return props.theme;
});

watchEffect(function () {
  if (scrollDirections.top) {
    lastScrollDirection.value = 'up';
    hasScrolledUp.value = true;
  } else if (scrollDirections.bottom) {
    lastScrollDirection.value = 'down';
  }
});

watch(
  function () {
    return route.path;
  },
  function () {
    isProjectPage.value = route.name === 'projekte-projekt_id';
    hasScrolledUp.value = false;

    if (isNavOpen.value) {
      toggleNav(1000);
    }
  },
  {
    immediate: true
  }
);

watch(isNavOpen, function () {
  if (!siteHeaderElement.value) {
    return;
  }

  if (isNavOpen.value) {
    siteHeaderElement.value.addEventListener('click', checkActiveRouteClicked);
  } else {
    siteHeaderElement.value.addEventListener('click', checkActiveRouteClicked);
  }
});

onMounted(function () {
  windowElement.value = window;
});

async function toggleNav(delay: number = 0, open = !isNavOpen.value) {
  isNavOpen.value = open;

  if (isNavOpen.value) {
    isNavVisible.value = true;
  } else if (delay) {
    await useSleep(delay);
    isNavVisible.value = false;
  } else {
    isNavVisible.value = false;
  }
}

function checkActiveRouteClicked(e: Event) {
  if (e.target instanceof HTMLAnchorElement) {
    const classes = e.target.classList;

    if (classes.contains('router-link-active')) {
      toggleNav(1000, false);
    }
  }
}
</script>

<style scoped lang="scss">
.site-header {
  &[data-theme='light'] {
    color: var(--color-white);

    .logo {
      :deep(img) {
        filter: invert(1);
      }
    }
  }

  &[data-theme='dark'],
  &.-nav-visible {
    color: var(--color-black);

    > .header {
      .logo {
        :deep(img) {
          filter: invert(0);
        }
      }
    }
  }

  &.-nav-transition {
    pointer-events: none;
  }

  &.-nav-open {
    > .header {
      background-color: var(--color-off-white);
    }
  }

  &.-scrolled {
    > .header {
      transition: background-color 200ms;
      background-color: var(--color-white);
      color: var(--color-black);

      .logo {
        :deep(img) {
          filter: invert(0);
        }
      }
    }
  }

  > .header {
    position: fixed;
    inset: 0;
    width: 100%;
    padding: 1.5rem calc(var(--base-component-padding-inline) + var(--site-outer-margin));
    height: 5rem;
    display: flex;
    z-index: 30;
    justify-content: space-between;
    align-items: center;
    transition: transform 500ms;

    > .column {
      height: 2.8125rem;
      width: fit-content;

      &.-logo {
        display: flex;
        align-items: center;
        gap: 2.8rem;

        > .logo {
          flex-shrink: 0;
          width: auto;
          height: 2.75rem;

          > .asset {
            width: auto;
            height: 2.75rem;
          }
        }
      }

      > .button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        padding: 0;
        margin: 0;
        color: currentColor;
        transition: color 100ms;
        height: 100%;

        @media (hover: hover) {
          &:focus {
            outline: unset;
          }
        }

        > .hamburger {
          @media (--vs) {
            display: inline-block;
          }
          @media (--vl) {
            display: none;
          }
        }

        > .buttontext {
          @include text-md(700);

          @media (--vs) {
            display: none;
          }
          @media (--vl) {
            display: inline-block;
          }
        }
      }
    }
  }
}
</style>
